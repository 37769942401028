// Generated by Framer (2edebc7)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { useOnVariantChange, useActiveVariantCallback, Image, transformTemplate, addFonts, withCSS, addPropertyControls, ControlType, cx, useAddVariantProps, useVariantState, CycleVariantState, Container, RichText, getFonts, Stack, useHydratedBreakpointVariants, removeHiddenBreakpointLayers, SSRVariants, GeneratedComponentContext } from "framer";
import { useRandomID } from "https://framer.com/m/framer/randomID.js@^2.0.0";
import Navigation from "../canvasComponent/GEoi1Pm9r";
const NavigationFonts = getFonts(Navigation);

const cycleOrder = ["ja7VHPYPJ"];

const breakpoints = {}

const isBrowser = typeof document !== "undefined";

const variantClassNames = {"ja7VHPYPJ": "framer-v-1uesjik"};

if (isBrowser) {
                    removeHiddenBreakpointLayers("ja7VHPYPJ", breakpoints, variantClassNames);
            }

const humanReadableVariantMap = {};

const transitions = {"default": {"duration": 0}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "ja7VHPYPJ", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const [baseVariant, hydratedBaseVariant] = useHydratedBreakpointVariants(variant, breakpoints, false);
const gestureVariant = undefined;
const transition = transitions.default;

const variantProps = React.useMemo(() => ({}), []);

const addVariantProps = useAddVariantProps(hydratedBaseVariant, gestureVariant, variantProps);

const defaultLayoutId = useRandomID();

const { pointerEvents, ...style } = externalStyle

return (<GeneratedComponentContext.Provider value={{ primaryVariantId: "ja7VHPYPJ", variantProps: variantProps, variantClassNames: variantClassNames}}>
<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated className={cx("framer-PDNxN")} style={{"display": "contents", "pointerEvents": pointerEvents ?? undefined}}>
<Stack {...restProps} className={cx("framer-1uesjik", className)} style={{...style}} name="Desktop"  direction="vertical" distribution="start" alignment="center" gap={329} __fromCanvasComponent={true} __contentWrapperStyle={{"width": "100%", "height": "100%", "padding": "0px 48px 0px 48px"}} ref={ref} {...addVariantProps("ja7VHPYPJ")}><Stack className="framer-1wuwqjw"   background={null} direction="horizontal" distribution="start" alignment="center" gap={10} __fromCanvasComponent={true} __contentWrapperStyle={{"width": "100%", "height": "auto", "padding": "0px 0px 0px 0px", "maxWidth": 744}} {...addVariantProps("TQW49nwMy")}><Container className="framer-1jwqzah-container"  {...addVariantProps("okUSnZmFU-container")}><Navigation width="100%" height="100%" layoutId="okUSnZmFU" id="okUSnZmFU" variant="fFdGd6igW" style={{"width": "100%", "height": "100%"}} {...addVariantProps("okUSnZmFU")}/></Container></Stack><RichText fonts={["GF;Manrope-800"]}  withExternalLayout={true} verticalAlignment="top"  __fromCanvasComponent={true}  className="framer-v1z8vv" __htmlStructure={"<p><span style=\"--framer-font-family:&quot;Manrope&quot;, serif; --framer-font-style:normal; --framer-font-weight:800; --font-selector:R0Y7TWFucm9wZS04MDA=; --framer-text-color:rgba(255, 255, 255, 0.6); --framer-font-size:100px; --framer-letter-spacing:-3px;\">{{ text-placeholder }}</span></p>"} htmlFromDesign={"<p><span style=\"--framer-font-family:&quot;Manrope&quot;, serif; --framer-font-style:normal; --framer-font-weight:800; --font-selector:R0Y7TWFucm9wZS04MDA=; --framer-text-color:rgba(255, 255, 255, 0.6); --framer-font-size:100px; --framer-letter-spacing:-3px;\">404</span></p>"} {...addVariantProps("IIIdcmSHm")}/></Stack>
<div id="overlay"/>
</motion.div>
</LayoutGroup>
</GeneratedComponentContext.Provider>)

});

const css = [".framer-PDNxN [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-PDNxN .framer-1uesjik { position: relative; width: 1200px; height: 932px; background-color: #101219; }", ".framer-PDNxN .framer-1wuwqjw { position: relative; overflow: visible; max-width: 744px; width: 100%; height: min-content; flex: none; }", ".framer-PDNxN .framer-1jwqzah-container { position: relative; z-index: 1; width: 1px; height: 80px; flex: 1 0 0px; }", ".framer-PDNxN .framer-v1z8vv { position: relative; z-index: 1; width: auto; height: auto; flex: none; white-space: pre; --framer-paragraph-spacing: 0px; --framer-link-text-color: #0099ff; --framer-link-text-decoration: underline; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 932
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName": "variant", "data": {"default": {"layout": ["fixed", "fixed"]}}}
 * @framerResponsiveScreen
 */
const FramerKBlFSas6i: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerKBlFSas6i;

FramerKBlFSas6i.displayName = "404";

FramerKBlFSas6i.defaultProps = {"width": 1200, "height": 932};

addFonts(FramerKBlFSas6i, [{"url": "https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59E-_F87jxeN7B.ttf", "family": "Manrope", "style": "normal", "weight": "800", "moduleAsset": {"url": "https://fonts.gstatic.com/s/manrope/v11/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59E-_F87jxeN7B.ttf", "localModuleIdentifier": "local-module:screen/KBlFSas6i:default"}}, ...NavigationFonts]);